$(window).scroll(function () {
    const wScroll = $(this).scrollTop();
    const header = $('#header');
    const snowBg = $('#snowBg');
    const snowFg = $('#snowFg');
    const woodBG = $('#woodBg');
    const nav = $('nav');

if(wScroll > 300){
    nav.addClass('nav-out');    
    
} else {
    nav.removeClass('nav-out')
} 

    header.css({
        top: wScroll / 2 + "px"
    })

    snowBg.css({
        bottom: (wScroll / 3) + 'px'
    })
    woodBG.css({
        bottom: 'calc(5vw + ' + (wScroll / 2.5) + 'px)'
    })

    snowFg.css({
        bottom: wScroll / 2 + 'px'
    })
})