const preisOutput = document.getElementById('preisContent');
let output = '';
let dayFlag = document.getElementById('day');
const dailyQuiz = document.getElementById('dailyQuiz');

const dateToday = new Date().getDate() > 24 ? 1 : new Date().getDate();

let currentDay = new Date().getDate();
let currentMonth = new Date().getMonth() + 1;
let currentYear = new Date().getFullYear();


// console.log(currentDay, currentMonth, currentYear);


dayFlag.innerHTML = new Date().getDate() > 24 && new Date().getMonth() < 11 ? 1 : new Date().getDate();


preise.map((item) => {
  const pDate = item.tag

    console.log(pDate)

  let futureDay = new Date(pDate).getDate();
  let futureMonth = new Date(pDate).getMonth() + 1;
  let futureYear = new Date(pDate).getFullYear();
  let flag;
  if (futureMonth === currentMonth && futureYear === currentYear) {
    if (futureDay < currentDay) {
      flag = 'past'
    }
    if (futureDay === currentDay) {
      flag = 'current'
    }
    if (futureDay > currentDay) {
      flag = 'current'
    }
  }

  if (futureYear > currentYear  || (futureYear === currentYear && futureMonth > currentMonth)) {
    flag = 'current'
  }
  if (futureYear < currentYear) {
    flag = 'past'
   }



  output += `
    <a onclick="void(0)" id="dec-${item.tag}" class="calendarItem ${flag}">
    <div class="calendarFront">
      <h4>${futureDay}</h4>
    </div>
    <div class="calendarBack">    
    ${ flag === 'future' ? '' :  `<div class="calendarImg"><img src="${path}${item.image}" alt=""></div><div class="calendarText"><p>${item.desc}</p></div>`}      
    </div>
  </a>`;
})


preisOutput.innerHTML = output;


// Fragen täglich einblenden
let quizfrage = '<h2 id="quiz">Hier findet Ihr die <br>jeweilige Frage des Tages</h2>';

for (let i = 0; i < fragen.length; i++) {
  const pDate = fragen[i].date

  let futureDay = new Date(pDate).getDate();
  let futureMonth = new Date(pDate).getMonth() + 1;
  let futureYear = new Date(pDate).getFullYear();

  if (futureDay === currentDay && futureMonth === currentMonth && futureYear === currentYear) {
    quizfrage = `<h2 id = "quiz"> Die heutige Aufgabe: </h2><p>${fragen[i].desc}</p> <img class="raetselImage" src="assets/img/raetsel/${fragen[i].image}">`
  }
}
dailyQuiz.innerHTML = quizfrage;