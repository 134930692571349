const winnerList = document.getElementById('winnerList');
const gewinnerSection = document.getElementById('gewinner');
let winnerOutput = "";



gewinner.length < 1 ? gewinnerSection.style.display = "none" : gewinnerSection.style.display = "block"
gewinnerSection.style.display = "block"

if (gewinner.length === 0) {
    document.getElementById('gewinner').style.display = "none"
} else { 
    document.getElementById('gewinner').style.display = ""
}

gewinner.map(item => {
    winnerOutput += `
        <li><span class="date">${item.date + " "}</span>${item.name}<br>
            <span class="loesung">Lösung: </span> <span>${item.answer}</span>
        </li><br>
    `
})

winnerList.innerHTML = winnerOutput;