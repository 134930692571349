const fragen = [
    {
    date: '2020/11/01',
    image: "1.png",
    desc: "Die Wichtel leiten den Dezember ein. Welches Getränk, um sich so richtig aufzuwärmen, darf da nicht fehlen? Setze das fehlende Wort mit Hilfe der Wichtel zusammen und trage es in das Lösungsfeld ein.",
    answer: "Glühwein"
    },
    {
    date: '2020/11/02',
    image: "2.png",
    desc: "Hoppla, da brauchen die Wichtel wohl deine Hilfe. Löse die Rechnung und trage das Ergebnis in das Lösungsfeld ein.",
    answer: "65"
    },
    {
    date: '2020/11/03',
    image: "3.png",
    desc: "'Na, wer kennt sich aus?",
    answer: "b) Mistelzweig, c) Pfefferkuchen"
    },
    {
    date: '2020/11/04',
    image: "4.png",
    desc: "Wichtel Max soll Ordnung schaffen. Er sucht dieselbe Christbaumkugel nochmal, um sie als Pärchen zu verpacken. Welche Kugel ist identisch mit Max Kugel?",
    answer: "12"
    },
    {
    date: '2020/11/05',
    image: "5.png",
    desc: "Oma hat die Weihnachtsgeschenke hinter einer der drei Türen weg geschlossen. Sieh durch das Schlüsselloch, um heraus zu finden, hinter welcher Tür die Geschenke versteckt sind.",
    answer: "c)"
}, 
{
    date: '2020/11/06',
    image: "6.png",
    desc: 'Die Wichtel freuen sich riesig, denn heute kommt der Nikolaus. Doch wie heißt sein treuer Begleiter? A) Knecht Roland B) Knecht Rüdiger C) Knecht Ruprecht.',
    answer: 'C) Knecht Ruprecht'
}, 
{
    date: '2020/11/07',
    image: "7.png",
    desc: "Die Wichtel gönnen sich eine kurze Pause von der Arbeit für den Weihnachtsmann und veranstalten eine Schneeballschlacht. Reichen die Schneebälle, sodass jeder Wichtel 7-mal werfen kann?",
    answer: "ja"
}, 
{
    date: '2020/11/08',
    image: "8.png",
    desc: "Heute bauen die Wichtel Schneemänner. Sie wissen aber nicht, für wie viele Schneemänner die herumliegenden Mützen und Schals reichen. Kannst du ihnen helfen und ihnen sagen, wie viele vollständige Schneemänner sie bauen können?",
    answer: "2"
}, 
{
    date: '2020/11/09',
    image: "9.png",
    desc: "Auf dem zweiten Bild haben sich wohl einige Fehler eingeschlichen. Wie viele Fehler kannst du finden? A) 7, B) 5, C) 3",
    answer: "B) 5"
}, 
{
    date: '2020/11/10',
    image: "10.png",
    desc: 'Draußen wird es dunkel und die Wichtel wollen dem Weihnachtsmann ihre Schneemänner zeigen. In der Dunkelheit können sie aber nicht mehr erkennen, welches ihre gewesen sind. Hilf den Wichteln! Ordne die Schneemänner ihrem Schatten zu.',
    answer: '9,2'
}, 
{
    date: '2020/11/11',
    image: "11.png",
    desc: 'Als die Wichtel heute morgen nach ihren Schneemännern schauen wollten, war nur noch einer da. Aber nicht nur das, anscheinend war in der Nacht ganz schön viel los. Welche Fußspuren kannst du im Schnee entdecken? <br>A) Schneemann. Rentier, Hase und Vögelchen; <br>B) Schneemann, Hase, Hund, Reh; <br>C) Vögelchen, Katze, Rentier, Echse; <br>D) Rentier, Hase, Schneemann, Bär',
    answer: 'A)'

}, 
{
    date: '2020/11/12',
    image: "12.png",
    image: '12.png',
    desc: 'Wichtel Max und Tim singen ein Lied für den Weihnachtsmann. Kannst du erkennen, um welches Lied es sich dabei handelt?',
    answer: 'Oh Tannenbaum'
}, 
{
    date: '2020/11/2134',
    image: "13.png",
    desc: 'Der Engelschor übt seinen Auftritt für Heiligabend, aber es sind noch nicht alle eingesungen. Kannst du erkennen, wie viele der Engel tatsächlich singen und wie viele noch nicht bereit sind? Schreibe die Anzahl der singenden Engelchen in das Lösungsfeld.',
    answer: '18'
}, 
{
    date: '2020/11/14',
    image: "14.png",
    desc: 'Wichtel Max (links) und Tim (rechts) stehlen sich von der Arbeit und gehen Rodeln. Welcher der beiden ist zuerst unten und gewinnt die Rodelfahrt? Rechne die Zeiten zusammen und trage die richtige Lösung ein.',
    answer: "Max"

}, 
{
    date: '2020/11/15',
    image: "15.png",
    desc: 'Die Wichtel haben Sorge, dass sie nicht fertig werden mit dem Verpacken der Geschenke. Wenn ein Wichtel 30 Sekunden zum Verpacken eines Geschenkes braucht, wie lange brauchen dann 7 Wichtel zum Verpacken von 140 Geschenken?',
    answer: '10 Minuten'
}, 
{
    date: '2020/11/16',
    image: "16.png",
    desc: 'Gesucht wird ein Wort: Errate die Begriffe auf den Bildern und schreibe die jeweiligen Buchstaben der Begriffe auf.',
    answer: 'Tannenbaumkugel'
}, 
{
    date: '2020/11/17',
    image: "17.png",
    desc: 'Wichtel Tim muss noch einige Geschenke zusammen suchen. Er hat noch 100€ zur Verfügung. Reicht das Geld, um alle Geschenke mitzunehmen? Schreibe deine Antwort in das Lösungsfeld.',
    answer: 'Ja'
    },
    {
    date: '2020/11/18',
    image: "18.png",
    desc: 'Oma hat die Geschenke alle unter dem Baum platziert, allerdings hat sie die Namensschilder vergessen und weiß nicht mehr, was in welchem Geschenk ist. Hilf Oma und schreibe die drei Begriffe in das Lösungsfeld.',
    answer: 'Fahrrad, Schlittschuh, Teddybär'

    },
    {
    date: '2020/11/19',
    image: "19.png",
    desc: 'Da hat sich wohl jemand bei dem zweiten Bild vermalt. Wie viele Fehler findest du? <br>a)3;	<br>b)2; <br>c)1',
    answer: 'c) 1'
    },
    {
    date: '2020/11/20',
    image: "20.png",
    desc: "'Na wer kennt sich aus?",
    answer: "a), a)"
    },
    {
    date: '2020/11/21',
    image: "21.png",
    desc: 'Finde die 5 Begriffe in diesem Buchstabendurcheinander und schreibe sie als Satz in das Lösungsfeld.',
    answer: 'Die Wichtel wünschen frohe Weihnachten'
    },
    {
    date: '2020/11/22',
    image: "22.png",
    desc: 'Voller Vorfreude haben sich die Wichtel entschieden, einen Weihnachtsfilm zu schauen. Kannst du herausfinden welchen? Trage die Lösung in das Lösungsfeld ein',
    answer: 'Drei Haselnüsse für Aschenbrödel'
    },
    {
    date: '2020/11/23',
    image: "23.png",
    desc: 'Die Buchstaben in den Geschenken sind beim Transport ganz durcheinander gekommen. Bringe die Buchstaben in die richtige Reihenfolge und erfahre was morgen passiert. Trage deine Lösung in das Lösungsfeld ein',
    answer: 'Morgen gibt es Geschenke'    
    },
    {
    date: '2020/11/24',
    image: "24.png",
    desc: 'Juhuu, endlich ist es soweit. Es ist Heiligabend, die Arbeit der Wichtel ist getan und nun feiern sie gemeinsam. Klicke auf das Bild, und finde heraus welches Weihnachtslied die Wichtel hören.',
    answer: 'Jingle Bells'

}]